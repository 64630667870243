// src/components/ButtonWithRipple.tsx

import React from 'react';
import './style.css';
import { ClipLoader } from 'react-spinners';

type Props = {
  label?: string;
  size?: 'xs' | 'md' | 'lg';
  icon?: string;
  iconLeft?: string;
  iconRight?: string;
  loading?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Index: React.FC<Props> = ({
  label = '',
  icon,
  iconLeft,
  iconRight,
  size = 'md',
  onClick,
  loading = false,
  disabled = false,
  showLabel = true,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick && !disabled) {
      onClick(event);
    }
  };

  const buttonClass = `button text-white button-${size} ${
    disabled ? 'button-disabled' : ''
  }`;
  return (
    <button className={buttonClass} onClick={handleClick}>
      {loading ? (
        <ClipLoader size={20} color="white" />
      ) : (
        <>
          <div className="label">
            {showLabel && label}
            {icon && (
              <>
                <i className={icon}></i>
              </>
            )}
            {iconRight && (
              <>
                <i className={`${iconRight} icon-right`}></i>
              </>
            )}

            {iconLeft && (
              <>
                <i className={`${iconLeft} icon-left`}></i>
              </>
            )}
          </div>
        </>
      )}
    </button>
  );
};

export default Index;

import LogoWhite from '@assets/logos/white.svg';
import './style.css';
import Buttom from '@components/buttons';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [isOpenMenu, setisOpenMenu] = useState(false);
  const openNav = () => {
    setisOpenMenu(!isOpenMenu);
  };

  const BinanceIcon = () => {
    return (
      <img src={require('./assets/images/bnb.png')} style={{ width: 35 }} />
    );
  };

  useEffect(() => {
    // Agregar clase al body al entrar en el componente
    document.body.classList.add('overflow-scroll');

    // Limpiar la clase al salir del componente
    return () => {
      document.body.classList.remove('overflow-scroll');
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const FAQItem = ({
    question,
    answer,
  }: {
    question: string;
    answer: string;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="faq-item">
        <button className="faq-question" onClick={() => setIsOpen(!isOpen)}>
          {question}
          <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
        </button>
        <div className={`faq-answer-container ${isOpen ? 'open' : ''}`}>
          <div className="faq-answer">{answer}</div>
        </div>
      </div>
    );
  };

  const faqData = [
    {
      question: 'Are multi-accounts allowed?',
      answer:
        'Creating multiple accounts on Mining Options from the same device is not allowed. This restriction does not apply to accounts that use the same network connection from a different device. Any attempt to cheat or misuse our application runs the risk of being detected and permanently blocked.',
    },
    {
      question: 'Can they ask for personal data (KYC) at any time?',
      answer:
        'We are a completely decentralized platform. We will not request personal data or provide sensitive information from your Mining Options account for any purpose at any time.',
    },
    {
      question: 'Can I increase my operation limit?',
      answer:
        'The operation limit is set based on the first amount entered into a participation contract and it resets again after 30 days, however this only applies to the selected contract you will be able to have access without any operation limit on contracts not yet used.',
    },
    {
      question: 'Can I cancel my participation contracts?',
      answer:
        'Your funds will always be available to withdraw or put into stake, regardless of the contract you select, you will always have the freedom to cancel it, giving up the rewards obtained so far without any commission.',
    },
    {
      question: 'Can staking rewards go up or down based on market conditions?',
      answer:
        'M-O is not dependent on market fluctuations, our returns are guaranteed for years to come thanks to our long-term vision and backed by legal contracts with node flow blockchain projects where we provide liquidity. Our goal is to continue with constant expansion where we will be at the service of the main networks in the market to ensure their scalability and sustainability.',
    },
    {
      question:
        'How can I get started, do I need to have knowledge about blockchain and mining?',
      answer:
        'With our innovative Stake system you do not need to have large capitals or have extensive knowledge to be able to obtain a small percentage of the block rewards on the network. Now you can participate from a very easy-to-use interface, compatible with Telegram Mini Apps and available for any device with an internet connection. All transactions are processed in USDT and on the Binance Smart Chain guaranteeing speed and low commissions.',
    },
  ];

  return (
    <div className="launch-container">
      <nav id="main-nav">
        <div className="content-nav">
          <div>
            <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
          </div>
          <div>
            <ul>
              <li>
                <Link to="/dmo-token">DMO Token</Link>
              </li>
              <li>
                <a
                  href="https://miningoptions.net/MININGOPTIONS.pdf"
                  target="_blank"
                  translate="no"
                >
                  White Paper
                </a>
              </li>
              <li className={location.hash === '#faq' ? 'activenav' : ''}>
                <a href="#faq">FAQ</a>
              </li>
              <li>
                <Link to="/team">Team</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="social-network">
            <a href="https://t.me/mining_options" target="_blank">
              <img
                src={require('./assets/images/telegram2.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
              target="_blank"
            >
              <img
                src={require('./assets/images/twitter-alt-circle.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <img
                src={require('./assets/images/instagram.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
              target="_blank"
            >
              <img
                src={require('./assets/images/youtube.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.binance.com/en/square/profile/mining_options"
              target="_blank"
            >
              <BinanceIcon />
            </a>
          </div>
          <div
            className="social-network"
            style={{
              width: 120,
              margin: '0 auto',
            }}
          ></div>
        </div>
        <div className="icon-bars-menu-launch" onClick={openNav}>
          <i className="fas fa-bars"></i>
        </div>
      </nav>
      <div
        className={`mobile-nav-menu-content ${isOpenMenu ? 'active' : ''}`}
        onClick={openNav}
      >
        <div className="mobile-nav-menu">
          <div className="items-menu">
            <div className="section-item-menu">
              <ul>
                <li className={`left-menu-item`}>
                  <Link to="/dmo-token">DMO Token</Link>
                </li>
                <li className={`left-menu-item`}>
                  <a
                    href="https://miningoptions.net/MININGOPTIONS.pdf"
                    target="_blank"
                    translate="no"
                  >
                    White Paper
                  </a>
                </li>

                <li className={`left-menu-item`}>
                  <Link to="/#faq">FAQ</Link>
                </li>
                <li className={`left-menu-item`}>
                  <Link to="/team">Team</Link>
                </li>
                <li className={`left-menu-item`}>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>

              <div
                style={{
                  width: 120,
                  margin: '0 auto',
                }}
              >
                <Buttom
                  size="lg"
                  label="Start Now"
                  onClick={() => {
                    navigate('/sing-in');
                  }}
                />
              </div>

              <div className="social-network" style={{ margin: '30px auto' }}>
                <a href="https://t.me/mining_options" target="_blank">
                  <img
                    src={require('./assets/images/telegram2.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
                  target="_blank"
                >
                  <img
                    src={require('./assets/images/twitter-alt-circle.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <img
                    src={require('./assets/images/instagram.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
                  target="_blank"
                >
                  <img
                    src={require('./assets/images/youtube.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.binance.com/en/square/profile/mining_options"
                  target="_blank"
                >
                  <BinanceIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** <p>The most innovative and profitable staking options in a one app</p>*/}
      <div id="hero">
        <h4>The most innovative and profitable staking options in a one app</h4>
      </div>
      <div className="launch-btns">
        <div>
          <Buttom
            size="lg"
            label="Desktop Launch"
            onClick={() => {
              navigate('/sing-in');
            }}
          />
        </div>
        <div>
          <Buttom
            size="lg"
            label="Telegram Launch"
            onClick={() => {
              window.location.href = 'https://t.me/Miningoptionsbot';
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <section className="padding-inline">
        <div id="usability">
          <div className="item-usability left">
            <div className="item">
              <h3>EASY TO USE</h3>
            </div>
            <div className="item">
              <h3>USDT REDWARDS</h3>
            </div>
          </div>
          <div id="image-phone">
            <img src={require('./assets/images/stake_home.png')} alt="" />
          </div>
          <div className="item-usability right">
            <div className="item">
              <h3>TELEGRAM COMPATIBILITY</h3>
            </div>
            <div className="item">
              <h3>NO PERSONAL DATA</h3>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="content padding-inline">
        <h1>MINING OPTIONS</h1>
        <div className="card-home">
          <p>
            Mining Options is a technological development of investments based
            on the integration of participation pools in our PoS and DPoS
            network. M-O offers a completely decentralized and secure service
            through a comfortable interface with values ​​expressed in USDT
            where you will not have to worry about high chain exchange fees to
            execute your operations or the constant price fluctuations of our
            main tokens and integrated nodes, thus delivering daily and stable
            rewards to all users. All this is possible thanks to a long-term
            focus of M-O and a participation contribution agreement with the
            associated chains to achieve greater scalability and security.
          </p>
        </div>
      </section>
      <br />
      <section id="core-features" className="content padding-inline">
        <center>
          <h1>CORE FEATURES</h1>
        </center>
        <br />

        <div className="flex-row">
          <div className="card-home">
            <h2>Permanent availability of your funds</h2>
            <p>
              At mining options we strive to optimize a rewards service
              according to the needs of our users, regardless of the selected
              blocking time you can withdraw or claim your funds in full and at
              any time, giving up your share of rewards obtained until the time
              of cancellation.
            </p>
          </div>
          <div className="card-home">
            <h2>Fast deposits and withdrawals process</h2>
            <p>
              With the integration of Binance Smart Chain we can guarantee
              automatic processing of deposits and withdrawals within our
              platform with the lowest possible fees.
            </p>
          </div>
        </div>
        <br />
        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Complete simple tasks on social networks and increase your
                rewards
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Get $DMO tokens for free during our exclusive airdrop event
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Earn extra USDT with entertaining mini games with immediate
                liquidity
              </p>
            </div>
          </div>
        </div>

        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Personalized support with a maximum response time of 24 hours
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Linking profiles for greater accessibility at all times
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Earn up to 8% of your direct referrals stake earnings
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br /> <br />
      <br />
      <section id="roadmap" className="content padding-inline">
        <center>
          <h1>ROADMAP</h1>
        </center>
        <br />

        <div className="flex-row gap20">
          <div className="card-home">
            <h2 className="text-center">PHASE 1 - 2024</h2>
            <ul className="active">
              <li>Telegram MiniApp integration</li>
              <li>Binance Smart Chain integration</li>
              <li>Rewarded Community Taks</li>
              <li>Launch Stake - Token Airdrop</li>
            </ul>
          </div>
          <div className="card-home">
            <h2 className="text-center">PHASE 2 - Q1/Q2 2025</h2>
            <ul>
              <li>Airdrop Allocation</li>
              <li>Internal $DMO transfers</li>
              <li>Pre-Market Trading $DMO</li>
              <li>$DMO LaunchPool</li>
            </ul>
          </div>
        </div>
        <br />
        <br />

        <div className="flex-row gap20">
          <div className="card-home">
            <h2 className="text-center">PHASE 3 - Q3/Q4 2025</h2>
            <ul>
              <li>$DMO TGE</li>
              <li>Exchange Listing</li>
              <li>$DMO Stake</li>

              <li>New Interface</li>
            </ul>
          </div>
          <div className="card-home">
            <h2 className="text-center">PHASE 4 - 2026</h2>
            <ul>
              <li>Games rewarded in usdt</li>
              <li>Web 3 integration</li>
              <li>Margin Trade Demo</li>
              <li>Launch Margin DEX</li>
            </ul>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section className="content">
        <div className="flex-row aling-items-center">
          <div className="laptop-content-img">
            <img src={require('./assets/images/stake.png')} alt="" />
          </div>
          <div>
            <div className="card-home">
              <h1>
                Access from any device and track your stakes in real time by
                linking your Google or Apple accounts with Telegram
              </h1>
            </div>
            <br />
            <br />

            <div id="content-access">
              <div>
                <img src={require('./assets/images/google.png')} alt="google" />
                <img src={require('./assets/images/apple.png')} alt="apple" />
                <img
                  src={require('./assets/images/telegram.png')}
                  alt="telegram"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /> <br />
      <section id="faq" className="content padding-inline">
        <center>
          <h1>FAQ</h1>
        </center>
        <br />
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
        <br />
      </section>
      <br />
      <br />
      <section className="content padding-inline" id="start-you-crypto">
        <br />
        <div className="card-home overflow-hidden">
          <div className="flex-row">
            <div className="item-start-your flex aling-items-center">
              <div style={{ width: '70%', margin: '0 auto' }}>
                <h2 className="text-center">
                  Start your crypto journey today and make profits from day one
                </h2>
                <br />
                <div style={{ width: '70%', margin: '0 auto' }}>
                  <Buttom
                    size="lg"
                    label="Register Now"
                    onClick={() => {
                      navigate('/sing-in');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item-start-your">
              <div className="flex-row justify-content-center">
                <img
                  src={require('./assets/images/STAKE_mining.png')}
                  alt="Stake Mining Options"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
      <br />
      <br />
      <br />
      <br />
      <section className="content padding-inline">
        <div id="footer-btns">
          <div>
            <Link to="/dmo-token">DMO Token</Link>

            <div>
              <a
                href="https://miningoptions.net/MININGOPTIONS.pdf"
                target="_blank"
                translate="no"
              >
                White Paper
              </a>
            </div>
            <Link to="/team">Team</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
      </section>
      <br />
      <br />
      <footer>
        <center>
          <p style={{ fontSize: 14 }}>
            &copy; 2024 - 2025 MiningOptions.net. All Rights Reserved.
          </p>
        </center>
      </footer>
      <br />
      <br />
    </div>
  );
};

export default Index;

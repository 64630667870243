import Layout from '@components/layout';
import './style.css';
import { AppStateType } from '@redux/store';
import { useSelector } from 'react-redux';

function App() {
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(user.user_code)
        .then(() => {
          alert('UID to clipboard!');
        })
        .catch((err) => {
          console.error('Error al copiar el enlace: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = user.user_code;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      alert('UID to clipboard!');
    }
  };

  return (
    <Layout
      icon={false}
      showBottomNav={false}
      showNotification={false}
      showNavMobile={false}
    >
      <div id="settings">
        <div className="item-settings" onClick={handleCopy}>
          <span>UID</span>
          <span className="value">{user.user_code}</span>
        </div>
        <br />
      </div>
    </Layout>
  );
}

export default App;
